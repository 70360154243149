import React from "react";
import Layout from "../components/layout";
import ContentCard from "../components/content-card";
import SEO from "../components/seo";

import { graphql, Link } from "gatsby";
import { css } from "@emotion/core";

export interface BlogsProps {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          frontmatter: {
            title: string;
            date: string;
            author: string;
            slug: string;
          };
        };
      }[];
    };
  };
}

const Blogs: React.FC<BlogsProps> = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout transparentHeaderOnTop={false}>
      <SEO title="Blogs" />
      <div className="container my-6 py-6 px-4">
        <h1 className="display-3 my-6 text-secondary">/Blog</h1>
        <div
          css={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))",
            gridGap: "1em",
            gap: "1em",
          }}
        >
          {edges.map(({ node }, index) => (
            <Link
              style={{ textDecoration: "none", color: "white" }}
              key={node.frontmatter.slug}
              to={`/blog/${node.frontmatter.slug}`}
            >
              <ContentCard
                title={node.frontmatter.title}
                backgroundImage={`/headers/${node.frontmatter.slug}.jpg`}
                short
                className="h-100"
              >
                <p className="mb-0">
                  <small>{node.frontmatter.date}</small>
                </p>
                <p className="mb-0">{node.frontmatter.author}</p>
              </ContentCard>
            </Link>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 1000
    ) {
      edges {
        node {
          frontmatter {
            title
            date(formatString: "DD-MM-YYYY")
            author
            slug
          }
        }
      }
    }
  }
`;

export default Blogs;
